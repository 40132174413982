/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, SeparateLine, SeparateLineWrap, Image, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"WoodArt Slovakia"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="pb--80 pt--80" name={"starostlivost"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Starostlivosť o produkty<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"8xbusw6233"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pt--80" style={{"paddingBottom":0,"marginBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"padas"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center pb--16" content={"Ako sa starať o podložky Padas"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l4"} name={"padas_starostlivost"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/35e7f911e1a345168591fbc1295fdd19_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/35e7f911e1a345168591fbc1295fdd19_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/35e7f911e1a345168591fbc1295fdd19_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/35e7f911e1a345168591fbc1295fdd19_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box" content={"Nedávať do umývačky riadu,<br>mohli by sa zdeformovať, prasknúť.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/db4c9f5560c7405eb5141080bdc0acb4_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/db4c9f5560c7405eb5141080bdc0acb4_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/db4c9f5560c7405eb5141080bdc0acb4_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/db4c9f5560c7405eb5141080bdc0acb4_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box" content={"Neumývať pod tečúcou vodou,<br>mohli by sa zdeformovať, prasknúť.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/f15ad02be4234629aaafb1a3bd12502f_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/f15ad02be4234629aaafb1a3bd12502f_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/f15ad02be4234629aaafb1a3bd12502f_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/f15ad02be4234629aaafb1a3bd12502f_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box" content={"Pri zašpinení utierať len vlhkou utierkou."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"2qgc736fvsw"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pt--80" style={{"paddingBottom":0,"marginBottom":0}} name={"dosky"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Ako sa starať o Servírovacie dosky/dosky na krájanie<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l4"} name={"dosky2"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/d2b572ee1b7c4496b911fd525b9abc13_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/d2b572ee1b7c4496b911fd525b9abc13_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/d2b572ee1b7c4496b911fd525b9abc13_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/d2b572ee1b7c4496b911fd525b9abc13_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box" content={"Nedávať do umývačky riadu,<br>mohli by sa zdeformovať, prasknúť."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/3b822f1ad6bf433f99199f67bfb6f696_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/3b822f1ad6bf433f99199f67bfb6f696_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/3b822f1ad6bf433f99199f67bfb6f696_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/3b822f1ad6bf433f99199f67bfb6f696_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box" content={"Opláchnite dosku teplou vodou a jemne ju umyte hubkou alebo handričkou.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/ddb6782fb4214e3c9668cfccea6759b1_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/ddb6782fb4214e3c9668cfccea6759b1_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/ddb6782fb4214e3c9668cfccea6759b1_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/ddb6782fb4214e3c9668cfccea6759b1_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box" content={"Po umytí dosku okamžite osušte čistou utierkou. Nechajte ju stáť vo vzpriamenej polohe, aby sa mohla úplne vysušiť na vzduchu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/8cf8cfade0c041a2af40967edf22a8c5_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/8cf8cfade0c041a2af40967edf22a8c5_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/8cf8cfade0c041a2af40967edf22a8c5_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/8cf8cfade0c041a2af40967edf22a8c5_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Upozorňujeme,&nbsp;</span>že na servírovacích doskách neodporúčame krájať!!!<br><span style=\"font-weight: bold;\">Na to sú určené dosky na krájanie.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"ij6vbizmxyb"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pt--80" style={{"paddingBottom":0}} name={"hodiny"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Ako sa starať o Hodiny"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l4"} name={"hodiny2"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/b56fc25032084862818104b9d3652693_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/b56fc25032084862818104b9d3652693_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/b56fc25032084862818104b9d3652693_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/b56fc25032084862818104b9d3652693_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box" content={"Použite jemnú, suchú alebo mierne navlhčenú utierku z mikrovlákna na pravidelné utieranie prachu. Vyhnite sa hrubým materiálom, ktoré by mohli poškriabať povrch.\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/c9214b79055e43ef863e874a290689bf_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/c9214b79055e43ef863e874a290689bf_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/c9214b79055e43ef863e874a290689bf_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/c9214b79055e43ef863e874a290689bf_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box" content={"Ak hodiny zle ukazujú čas je na čase vymeniť batériu."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"xc8zhvl2oxq"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pt--80" style={{"paddingBottom":0}} name={"akosastaratosvietniky1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Ako sa starať o Svietniky"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l4"} name={"akosastartosvietniky"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/a924382a82a84c7288c37192cb38804e_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/a924382a82a84c7288c37192cb38804e_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/a924382a82a84c7288c37192cb38804e_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/a924382a82a84c7288c37192cb38804e_s=860x_.png 860w"}>
              </Image>

              <Text className="text-box" content={"Nikdy nenechávajte horiace sviečky bez dozoru a zabezpečte, aby sa plameň nachádzal mimo dosahu horľavých materiálov.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/978a6494ccbe48e78faaba9c2db22ef2_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/978a6494ccbe48e78faaba9c2db22ef2_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/978a6494ccbe48e78faaba9c2db22ef2_s=660x_.png 660w"}>
              </Image>

              <Text className="text-box" content={"Pravidelne utierajte svietnik od prachu a nečistôt vlhkou handričkou, aby ste zachovali jeho krásu a lesk.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/19338af920c848f69103877b2d3d2a04_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":164}} srcSet={"https://cdn.swbpg.com/t/39117/19338af920c848f69103877b2d3d2a04_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box" content={"Vychutnajte si atmosféru, ktorú svietnik vytvára, a zdieľajte chvíle s rodinou a priateľmi pri jeho svetle."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"lxvqjlifgjs"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"n3ktqk4xauj"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"pata"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--left fs--26" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box text-box--left pt--08" style={{"marginTop":0}} content={"<a href=\"tel:+421 905 310 613\" style=\"color: var(--black);\">+421 905 310 613</a><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><a href=\"mailto:info.woodartsk@gmail.com\" style=\"color: var(--black);\">info.woodartsk@gmail.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":462}}>
              
              <Title className="title-box" style={{"marginBottom":0}} content={"Andrej Nagy<br>"}>
              </Title>

              <Image style={{"maxWidth":148,"marginTop":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--right fs--26" content={"Návody a Starostlivosť<br>"}>
              </Title>

              <Text className="text-box text-box--right pt--08" style={{"marginTop":0}} content={"<a href=\"/navod-montaz\" style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Zavesenie hodín</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":0}} content={"<a href=\"/starostlivost\" style=\"color: var(--black); background-color: rgb(255, 255, 255);\">Starostlivosť</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}